<template>
  <DetailTemplate
    :custom-class="'quotation-detail detail-page'"
    v-if="getPermission('orders:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0 flex-wrap align-center">
          <!-- <Status :status="customerActivated"></Status> -->
          <template v-if="detail && detail.status">
            <span class="my-1">
              <CustomStatus
                :status="detail.status"
                endpoint="orders/status"
              ></CustomStatus>
            </span>
            <span
              class="font-weight-bold color-custom-blue font-size-21 ml-2 my-1"
              >{{ formatMoney(detail.grand_total) }}</span
            >
          </template>
        </div>

        <!--   <div>
          <span
            class="font-weight-bold color-custom-blue font-size-21 ml-2 my-1"
            >{{ formatMoney(detail.grand_total) }}</span
          >
        </div> -->
        <div class="mt-5 px-0">
          <table width="100%">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Order Information
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Order #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-chip
                  label
                  color="chip-custom-blue"
                  outlined
                  class="text-white p-3"
                  small
                  >{{ detail.barcode }}
                </v-chip>
              </td>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Costing #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="detail?.costing_relation?.barcode">
                  <router-link
                    :to="{
                      name: 'admin.costing.detail',
                      params: { id: detail.costing_relation.id },
                    }"
                  >
                    <v-chip
                      label
                      color="red"
                      class="text-white p-3 mb-1"
                      outlined
                      small
                      >{{ detail.costing_relation.barcode }}
                    </v-chip>
                  </router-link>
                </template>
                <em v-else class="text-muted"> no costing</em>
              </td>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Quotation #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="detail?.quotation_relation?.barcode">
                  <router-link
                    :to="{
                      name: 'admin.quotation.detail',
                      params: { id: detail.quotation_relation.id },
                    }"
                  >
                    <v-chip
                      label
                      :color="
                        getQuotationStatusColor(
                          detail?.quotation_relation?.status
                        )
                      "
                      class="p-3 mb-1"
                      outlined
                      small
                      >{{ detail.quotation_relation.barcode }}
                    </v-chip>
                  </router-link>
                </template>
                <em v-else class="text-muted"> no quotation</em>
              </td>
            </tr>

            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Reference<!-- Customer Reference # -->
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0" v-if="detail.reference">{{
                  detail.reference
                }}</label>
                <em class="text-muted" v-else> no customer reference</em>
              </td>
            </tr>

            <tr v-if="detail.sale_by_relation">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Sales representatives
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail.sale_by_relation.id },
                    }"
                    ><v-chip
                      v-if="
                        detail &&
                        detail.sale_by_relation &&
                        detail.sale_by_relation.display_name
                      "
                      small
                      class="mb-1 mr-1"
                    >
                      <v-avatar left small>
                        <img
                          v-if="
                            detail.sale_by_relation &&
                            detail.sale_by_relation.customeProfile_logo &&
                            detail.sale_by_relation.customeProfile_logo.file
                          "
                          :src="
                            detail.sale_by_relation.customeProfile_logo.file.url
                          "
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ detail.sale_by_relation.display_name }}</span>
                    </v-chip></router-link
                  ></label
                >
              </td>
            </tr>
            <tr v-if="detail.quote_by_relation">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Prepared by
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0"
                  ><router-link
                    :to="{
                      name: 'admin.user.detail',
                      params: { id: detail.quote_by_relation.id },
                    }"
                  >
                    <v-chip
                      v-if="
                        detail &&
                        detail.quote_by_relation &&
                        detail.quote_by_relation.display_name
                      "
                      small
                      class="mb-1 mr-1"
                    >
                      <v-avatar left small>
                        <img
                          v-if="
                            detail.quote_by_relation &&
                            detail.quote_by_relation.custome_profile_logo &&
                            detail.quote_by_relation.custome_profile_logo.file
                          "
                          :src="
                            detail.quote_by_relation.custome_profile_logo.file
                              .url
                          "
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ detail.quote_by_relation.display_name }}</span>
                    </v-chip>
                  </router-link></label
                >
              </td>
            </tr>
            <tr v-if="detail.added_at">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Created
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ formatDate(detail.added_at) }}
                </label>
              </td>
            </tr>
            <tr v-if="detail.validity_till && detail.validity_till > 0">
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Validity
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">
                  {{ getValidityDate(detail.validity_till) }}
                  <span class="red--text">
                    ({{ detail.validity_till }}Days)
                  </span>
                </label>
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <span
        v-if="pageLoaded"
        class="color-custom-blue font-weight-700 font-size-20 mr-2"
        >{{ detail.title }}</span
      >
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template v-if="detail.status == 4">
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="green"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_accepted')"
              >
                <v-icon left>mdi-check-decagram-outline</v-icon> Confirm
              </v-btn>
            </template>
            <span>Mark As Confirmed</span>
          </v-tooltip>
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="statusLoading"
                class="mx-1 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_rejected')"
              >
                <v-icon left>mdi-close-thick</v-icon> Reject
              </v-btn>
            </template>
            <span>Mark As Rejected</span>
          </v-tooltip>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
          style="z-index: 99 !important"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :disabled="statusLoading"
              class="mx-1 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          depressed
          class="mx-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <!--  <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll quotation-detail-height"
        style="position: relative"
      > -->
      <v-container fluid v-if="pageLoaded">
        <!--  <v-row v-if="pageLoaded">
            <v-col md="12"> -->
        <v-tabs
          active-class="custom-tab-active"
          v-model="orderTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>

          <v-tab class="font-size-16 font-weight-600 px-8" href="#attachments">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
              <!--end::Svg Icon-->
            </span>
            Attachments
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="orderTab">
          <v-tab-item value="other">
            <v-container fluid>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >Location Detail</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <span class="color-custom-blue font-weight-700 font-size-19">
                    <router-link
                      :to="
                        getDefaultRoute('customer.detail', {
                          params: {
                            id: detail?.customer_relation.id,
                          },
                        })
                      "
                      >{{ detail?.customer_relation.display_name }}
                    </router-link>
                  </span>
                  <v-layout class="my-4">
                    <v-flex md6 class="mr-2 custom-border-right">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Site Location</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <template>
                              <div
                                class="custom-flex-d"
                                v-if="detail?.property_relation?.property_name"
                              >
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                <span
                                  >{{ detail?.property_relation?.property_name
                                  }}<br
                                /></span>
                              </div>
                              <div v-else>
                                <em class="text-muted">
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                  no Site Name</em
                                >
                              </div>
                            </template>

                            <template>
                              <div class="custom-flex-d">
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-map-marker
                                  </v-icon>
                                </b>
                                <span>
                                  <template
                                    v-if="detail?.property_relation?.unit_no"
                                    >{{
                                      detail?.property_relation?.unit_no
                                    }},</template
                                  >
                                  <template
                                    v-if="detail?.property_relation?.street_1"
                                    >{{
                                      detail?.property_relation?.street_1
                                    }},</template
                                  >
                                  {{ detail?.property_relation?.street_2 }}
                                  <br />
                                  {{ detail?.property_relation?.country }}
                                  <template
                                    v-if="
                                      detail?.property_relation?.zip_code &&
                                      detail?.property_relation?.zip_code !=
                                        '000000'
                                    "
                                  >
                                    {{ detail?.property_relation?.zip_code }}
                                  </template>
                                </span>
                              </div>
                            </template>

                            <!--  <a v-if="detail &&
                                detail.property &&
                                detail.property.property_address
                                " target="_blank"
                                                          :href="`https://maps.google.com/?q=${detail.property.property_address}`">{{
                                detail.property.property_address }}</a> -->
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="
                            detail?.property_contact_person_relation &&
                            detail?.property_contact_person_relation
                              .display_name
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              detail?.property_contact_person_relation
                                .display_name
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            detail?.property_contact_person_relation &&
                            detail?.property_contact_person_relation
                              .primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              detail?.property_contact_person_relation
                                .primary_phone
                            }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            detail?.property_contact_person_relation &&
                            detail?.property_contact_person_relation
                              .primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              detail?.property_contact_person_relation
                                .primary_email
                            }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-2">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Billing Address</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <template>
                              <div
                                class="custom-flex-d"
                                v-if="detail?.billing_relation?.property_name"
                              >
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                <span
                                  >{{ detail?.billing_relation?.property_name
                                  }}<br
                                /></span>
                              </div>
                              <div v-else>
                                <em class="text-muted">
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                  no Site Name</em
                                >
                              </div>
                            </template>

                            <template>
                              <div class="custom-flex-d">
                                <b>
                                  <v-icon
                                    style="
                                      font-size: 18px;
                                      margin-top: -3px !important;
                                    "
                                    color="#000"
                                    class="cmt-2"
                                  >
                                    mdi-map-marker
                                  </v-icon>
                                </b>
                                <span>
                                  <template
                                    v-if="detail?.billing_relation.unit_no"
                                    >{{
                                      detail?.billing_relation.unit_no
                                    }},</template
                                  >
                                  <template
                                    v-if="detail?.billing_relation.street_1"
                                    >{{
                                      detail?.billing_relation.street_1
                                    }},</template
                                  >
                                  {{ detail?.billing_relation.street_2 }}
                                  <br />
                                  {{ detail?.billing_relation.country }}
                                  <template
                                    v-if="
                                      detail?.billing_relation.zip_code &&
                                      detail?.billing_relation.zip_code !=
                                        '000000'
                                    "
                                  >
                                    {{ detail?.billing_relation.zip_code }}
                                  </template>
                                </span>
                              </div>
                            </template>

                            <!--  <a v-if="detail &&
                            detail.billing &&
                            detail.billing.property_address
                            " target="_blank"
                                                      :href="`https://maps.google.com/?q=${detail.billing.property_address}`">{{
                            detail.billing.property_address }}</a> -->
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="
                            detail?.billing_contact_person_relation &&
                            detail?.billing_contact_person_relation.display_name
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              detail?.billing_contact_person_relation
                                .display_name
                            }}
                          </td>
                        </tr>

                        <tr
                          v-if="
                            detail?.billing_contact_person_relation &&
                            detail?.billing_contact_person_relation
                              .primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              detail?.billing_contact_person_relation
                                .primary_phone
                            }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            detail?.billing_contact_person_relation &&
                            detail?.billing_contact_person_relation
                              .primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              detail?.billing_contact_person_relation
                                .primary_email
                            }}
                            <!-- <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        > -->
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="custom-grey-border remove-border-radius mb-4"
                v-if="detail.status == 2 || detail.status == 3"
              >
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >{{ get_remark_title() }}</span
                  >
                </v-card-title>
                <v-card-text
                  class="p-6 font-size-16"
                  v-if="detail.status == 2 || detail.status == 3"
                >
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class=""
                      v-if="detail && detail.additional_remarks"
                      more-str="read more"
                      :text="
                        detail.additional_remarks ||
                        `<em>No ${get_remark_title()}</em>`
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >Notes</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout class="mb-4">
                    <v-flex md12 class="custom-border-right">
                      <v-card-title class="headline grey lighten-4 py-1">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Internal Note
                        </span>
                      </v-card-title>
                      <read-more
                        class="mx-4 mt-1 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail.admin_remark || '<em>no Internal Note</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                    <!-- <v-flex md6>
                      <v-card-title class="headline grey lighten-4 py-1">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Client Note
                        </span>
                      </v-card-title>

                      <read-more
                        class="mx-4 mt-1 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          detail.client_remark || '<em>No Client Note </em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex> -->
                  </v-layout>
                </v-card-text>
              </v-card>

              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >TERMS &amp; CONDITIONS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div
                    class="w-100 custom-grey-border p-4 mb-2"
                    v-if="
                      detail &&
                      detail.term_condition_id &&
                      detail.term_conditions_title &&
                      detail.term_conditions_title.label
                    "
                  >
                    {{ detail.term_conditions_title.label }}
                  </div>
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class="mx-2 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                      more-str="read more"
                      :text="
                        detail.term_conditions ||
                        '<em>No Terms &amp; Conditions</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-1">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >DESCRIPTION</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class=""
                      v-if="detail && detail.description"
                      more-str="read more"
                      :text="detail.description || '<em>No Description</em>'"
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item value="line-item">
            <ViewLineItem is-quotation />
          </v-tab-item>
          <v-tab-item value="milestones"> </v-tab-item>
          <v-tab-item value="invoice">
            <InternalInvoice
              type="quotation"
              :type-id="orderId"
            ></InternalInvoice>
          </v-tab-item>
          <v-tab-item value="visit">
            <VisitInternalAll
              :detail="detail"
              type="quotation"
              :quotation-id="orderId"
            ></VisitInternalAll>
          </v-tab-item>
          <v-tab-item value="attachments">
            <AdditionalDocuments
              :type-id="detail.id"
              class="mx-4"
              type-text="Order Attachments"
              :type="5021"
              :extensive="false"
            >
            </AdditionalDocuments>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="quotation_order"
              :type_id="detail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('history:view')"
            :value="'pdfHistory'"
          >
            <PDFHistoryDetail
              type="quotation"
              :type_id="detail.id"
              v-on:success="getOrder()"
            >
            </PDFHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
        <!-- </v-col>
          </v-row> -->
      </v-container>
      <!--  </perfect-scrollbar> -->
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="quotation"
        v-on:close="mail_dialog = false"
        :type-id="orderId"
        v-on:success="getOrder()"
      ></SendMail>
      <Dialog :common-dialog="confirm_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p
                class="font-weight-600 font-size-19"
                v-if="confirm_type != 'mark_as_send'"
              >
                Are you sure, you want to
                <span class="font-weight-700">{{ get_confirm_title() }}</span
                >?
              </p>
              <p v-else class="font-weight-600 font-size-19">
                Are you sure you have
                <span class="font-weight-700"
                  >send out the order..... already to customer</span
                >?
              </p>
              <v-layout
                class="my-4"
                v-if="
                  confirm_type == 'mark_as_approved' ||
                  confirm_type == 'mark_as_rejected' ||
                  confirm_type == 'mark_as_accepted' ||
                  confirm_type == 'mark_as_unapproved'
                "
              >
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <TextAreaField
                    :rules="[
                      validateRules.required(actionData.remark, 'Remark'),
                    ]"
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="actionData.remark"
                    placeholder="Remark"
                    solo
                    flat
                    row-height="25"
                    counter="200"
                  />
                </v-flex>
              </v-layout>

              <v-layout
                class="mt-4"
                v-if="
                  confirm_type == 'mark_as_accepted' ||
                  confirm_type == 'mark_as_rejected' ||
                  confirm_type == 'mark_as_approved' ||
                  confirm_type == 'mark_as_unapproved'
                "
              >
                <v-flex md12>
                  <label
                    class="font-weight-500 font-size-16"
                    :class="
                      confirm_type == 'mark_as_accepted' ? 'required' : ''
                    "
                    >Attachment</label
                  >

                  <file-upload v-model="actionData.attachments" :maxLimit="5">
                  </file-upload>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="confirm_submit()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="visit_count_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Visit Id Listing On Same Quotation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <div class="font-weight-600 font-size-19">
                <!--    {{detail.ticket_multiple}} -->
                <table>
                  <th>Visit #</th>
                  <th>Date</th>
                  <!-- <th>Date</th> -->
                  <tr>
                    <td>
                      <template v-for="(row, index) in detail.ticket_multiple">
                        <span :key="index">
                          <v-chip
                            label
                            :color="getColor(row.visit[0].status_text)"
                            class="text-white p-2 mb-2"
                            small
                          >
                            {{ row.visit[0].barcode }}
                          </v-chip>
                          <br />
                        </span>
                      </template>
                    </td>
                    <td>
                      <template v-for="(row, index) in detail.ticket_multiple">
                        <span :key="index">
                          <v-chip
                            v-if="row && row.visit && row.visit[0]"
                            label
                            color="cyan"
                            class="text-white p-2 mb-2"
                            small
                          >
                            {{ formatDate(row.visit[0].finished_at, "") }}
                          </v-chip>
                          <br />
                        </span>
                      </template>
                    </td>
                    <!-- <td>
                        <template v-for="(row, index) in detail.ticket_multiple">
                          <span :key="index">
                            <v-chip v-if="row && row.visit && row.visit[0] && row.visit[0].teams && row.visit[0].teams.barcode" label color="cyan" class="text-white p-2" small>
                              {{row.visit[0].teams.barcode}}
                            </v-chip>
                          </span>
                        </template>
                      </td> -->
                  </tr>
                  <!--  <tr>
                  
                      <template v-for="(row, index) in detail.ticket_multiple">
                        
                        <span :key="index">
                          <v-chip v-if="row && row.visit && row.visit[0] && row.visit[0].teams && row.visit[0].teams.team_name" label color="cyan" class="text-white p-2 ml-2 mb-2" small>
                            {{ row.visit[0].teams.team_name}}
                          </v-chip>

                        </span>
                      </template>
                  
                 </tr> -->
                </table>
              </div>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="visit_count_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="(confirm = true), routeToJob()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="download_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Download Quotation </template>
        <template v-slot:body>
          <v-container class="px-10" fluid>
            <v-col md="12">
              <v-autocomplete
                dense
                color="cyan"
                filled
                id="prepared-by"
                :items="templateList"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.trim="download_template"
                label="Download Template"
                solo
                flat
                item-color="cyan"
                item-text="title"
                item-value="value"
                hide-details
                v-on:change="updatePreparedBy()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Template Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12" v-if="download_template == 'HYC_Pn_Inst'">
              <label class="font-weight-700 font-size-16"
                >Installation Clause</label
              >
              <!-- <v-textarea
                  auto-grow
                  dense
                  filled
                  flat
                  v-model="profeinstaservice.profe_insta_service"
                  label="Professional Services and Installation Services"
                  solo
                  color="cyan"
                  hide-details
                  row-height="10"
                  
                ></v-textarea> -->
              <editor
                :disabled="formLoading"
                v-model="profeinstaservice.profe_insta_service"
              />
            </v-col>
            <v-col>
              <label class="font-weight-700 font-size-16">Download Date</label>
              <v-menu
                v-model="DatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Download Date"
                    hide-details
                    solo
                    :rules="[
                      validateRules.required(download_date, 'Download Date'),
                    ]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedDownloadDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="download_date"
                  color="cyan"
                  :min="todayDate"
                  v-on:input="DatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label class="font-weight-700 font-size-16">Valid Till</label>
              <v-menu
                v-model="ValidDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Valid Till"
                    hide-details
                    solo
                    :rules="[validateRules.required(valid_till, 'Valid Till')]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedValidDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="valid_till"
                  color="cyan"
                  :min="todayDate"
                  v-on:input="ValidDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="download_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Close
          </v-btn>
          <v-btn
            v-on:click="download_submit()"
            :disabled="confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Download
          </v-btn>
        </template>
      </Dialog>
      <DeleteDialog :delete-dialog="deleteQuotationDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ detail.barcode }}</span
            >, you won't be able to retrieve this later.<br />Are you sure you
            want to delete
            <span class="font-weight-700">{{ detail.barcode }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteQuotationLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteQuotationDialog = false"
            >No! Cancel</v-btn
          >
          <v-btn
            :disabled="deleteQuotationLoading"
            :loading="deleteQuotationLoading"
            color="red lighten-1 white--text"
            v-on:click="deleteQuotationConfirm()"
            class="mx-2 custom-grey-border custom-bold-button"
            >Yes! Delete</v-btn
          >
        </template>
      </DeleteDialog>
      <Dialog :commonDialog="typeConfirmDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col lg="12">
                <RadioInputCustom
                  v-model="jobType"
                  row
                  color="blue darken-4"
                  :items="[
                    {
                      label: 'Adhoc Job',
                      value: 'one-off',
                    },
                    {
                      label: `Recurring Job`,
                      value: 'recurring',
                    },
                  ]"
                ></RadioInputCustom>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="pushToVisit()"
          >
            Continue
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="typeConfirmDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <FullDialog
        v-if="notesDialog"
        :dialog="notesDialog"
        content-class="testdata"
      >
        <template v-slot:title
          >{{ detail.title }}(
          {{ detail.barcode }}
          ) Notes
          <CustomStatus
            :status="detail.status"
            endpoint="quotation/status"
          ></CustomStatus>

          <v-spacer></v-spacer>
          <v-btn
            class="text-white"
            color="red darken-4"
            depressed
            tile
            v-on:click="notesDialog = false"
          >
            Close
          </v-btn>
        </template>
        <template v-slot:body>
          <ChatNote
            v-if="notesDialog"
            :relatedId="orderId"
            :relatedType="'quotation'"
            :documentTypes="32"
            create-url="quotation-notes"
            get-url="quotation-notes"
          ></ChatNote>
        </template>
        <!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
      </FullDialog>
      <!--    <div class="floating-btn-wrapper">
        <div class="position-relative">
          <div
            class="call-btn-underlay"
            style="background-color: rgb(43, 149, 105)"
          ></div>
          <v-icon
            style="font-size: 50px !important"
            color="green"
            small
            v-on:click="notesDialog = true"
            >mdi-chat
          </v-icon>
        </div>
      </div> -->
    </template>
  </DetailTemplate>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import ChatNote from "@/view/pages/partials/ChatNote";
import FullDialog from "@/view/components/FullDialog";
/* import QuotationLineItem from "@/view/pages/partials/Line-Item-Detail-New"; */
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import DeleteDialog from "@/view/pages/partials/Delete";

import SendMail from "@/view/pages/partials/Send-Mail";
import VisitInternalAll from "@/view/pages/partials/Detail/VisitInternalAll.vue";

import {
  GET,
  /* QUERY, */ PATCH,
  DELETE,
  PUT,
} from "@/core/services/store/request.module";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import PDFHistoryDetail from "@/view/pages/partials/Detail/PDF-History-Detail.vue";
import JwtService from "@/core/services/jwt.service";
import Dialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import FileUpload from "@/view/components/app-component/FileInput";
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
/* import Barcode from "@/view/pages/partials/Barcode.vue"; */
import RadioInputCustom from "@/view/components/RadioInputCustom.vue";
import ViewLineItem from "@/view/components/ViewLineItem.vue";
import {
  SET_VIEW_CALCULATION,
  SET_VIEW_LINE_ITEM,
} from "@/core/services/store/line.item.v2.module";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      notesDialog: false,
      jobType: "one-off",
      typeConfirmDialog: false,
      formLoading: false,
      is_quotation_invoice_allow: false,
      tab: null,
      orderId: 0,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      invoiceLoading: false,
      deleteQuotationDialog: false,
      pageLoading: false,
      todayDate: null,
      DatePicker: false,
      deleteQuotationLoading: false,
      ValidDatePicker: false,
      signature: 0,
      confirm_loading: false,
      visit_count_dialog: false,
      download_template: "HYC_Pn_Inst",
      download_dialog: false,
      confirm_dialog: false,
      download_date: moment().format("YYYY-MM-DD"),
      //valid_till: moment().format("YYYY-MM-DD"),
      valid_till: moment().add(14, "days").format("YYYY-MM-DD"),
      confirm_type: null,
      /*   orderTab: "other", */
      payment_in_milestones: false,
      moreActions: [],
      detail: {},
      db_approval: {},
      dbLineItems: [],
      dbEquipments: [],
      confirm: false,
      templateList: [],
      actionData: {
        remark: null,
        attachments: [],
        status: null,
      },
      userDetails: new Object(),
      attachments: [],
      profeinstaservice: {
        profe_insta_service: null,
        quotation: null,
      },
    };
  },
  methods: {
    getDueDay(day) {
      if (day == 2) {
        return 15;
      } else if (day == 3) {
        return 30;
      } else if (day == 4) {
        return 45;
      }
    },
    updateAttachment(param) {
      this.attachments = param;
    },
    lengthCount(param) {
      this.itemCount = param;
    },
    viewEnquiry(id) {
      this.$router.push(
        this.getDefaultRoute("enquiry.detail", {
          params: { id: id },
        })
      );
    },
    pageReload() {
      window.location.reload();
    },
    deleteVisit() {
      this.deleteQuotationDialog = true;
    },
    deleteQuotationConfirm() {
      this.deleteQuotationLoading = true;
      this.$store
        .dispatch(DELETE, { url: `quotation/${this.detail.id}` })
        .then(() => {
          this.deleteQuotationDialog = false;
          this.$nextTick(() => {
            this.$router.push(this.getDefaultRoute("quotation"));
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteQuotationLoading = false;
        });
    },
    getValidityDate(days) {
      if (this.detail.added_at) {
        let date = moment(this.detail.added_at, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        );
        let validity = moment(date, "YYYY-MM-DD")
          .add(days, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    refressData() {
      this.getOrder();
    },
    get_confirm_title() {
      if (this.confirm_type == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirm_type == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirm_type == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirm_type == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirm_type == "mark_as_accepted") {
        return "Mark as Confirmed";
      } else if (this.confirm_type == "mark_as_approved") {
        return "Mark as Approve";
      } else if (this.confirm_type == "mark_as_send") {
        return "Send Quotation";
      } else if (this.confirm_type == "submited_for_Approver") {
        return "Submit For Approval";
      } else if (this.confirm_type == "mark_as_unapproved") {
        return "Unapprove";
      }
    },
    getColor(data) {
      if (data == "Open") {
        return "blue";
      } else if (data == "In-Progress") {
        return "yellow";
      } else if (data == "Hold") {
        return "red";
      } else if (data == "Completed") {
        return "green";
      } else if (data == "Cancelled") {
        return "red";
      } else if (data == "Hold & Unassigned") {
        return "cyan";
      }
    },
    getColorType(data) {
      if (data == 1) {
        return "green darken-3";
      } else if (data == 2) {
        return "orange darken-3";
      } else if (data == 3) {
        return "red darken-3";
      }
    },
    getColorText(data) {
      if (data == 1) {
        return "New";
      } else if (data == 2) {
        return "Maintenance";
      } else if (data == 3) {
        return "General";
      }
    },
    routeToVisit(id) {
      this.$router.push({
        name: "admin.visit.detail",
        params: { id },
      });
    },

    confirm_submit() {
      const _this = this;
      const validateStatus = _this.$refs.statusForm.validate();
      const formErrors = _this.validateForm(_this.$refs.statusForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      if (!validateStatus) {
        return false;
      }
      if (
        this.confirm_type == "mark_as_accepted" &&
        this.actionData?.attachments?.length == 0
      ) {
        ErrorEventBus.$emit("update:error", "Attachment is required");
        return false;
      }
      _this.confirm_loading = true;
      switch (this.confirm_type) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_send":
          this.updateStatus("mark_as_send");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
        case "mark_as_unapproved":
          this.updateStatus("mark_as_unapproved");
          break;
        case "submited_for_Approver":
          this.updateStatus("submited_for_Approver");
          break;
      }
    },
    async download_submit() {
      if (this.download_template && this.download_dialog) {
        await this.ProfesnalInstaService();
      }
    },
    downloadPDFNew() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}order/${id}/pdf/download/${this.download_template}?token=${token}&date=${this.download_date}`;
      // console.log(url);
      window.open(url, "_blank");
      this.profeinstaservice.profe_insta_service = this.profeinstaservice
        .profe_insta_service
        ? this.profeinstaservice.profe_insta_service
        : this.detail.profe_insta_service;
      this.download_dialog = false;
    },
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.signature = data.signature.value;
          _this.payment_in_milestones = data.payment_in_milestones;
          _this.is_quotation_invoice_allow = data.is_quotation_invoice_allow;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateMoreAction(type) {
      switch (type) {
        /*   case "edit":
          this.editQuotation();
          break; */
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "re_quote":
          this.requoteQuotation();
          break;
        case "mark_as_closed":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;

        case "mark_as_pending":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_accepted";
          break;
        case "mark_as_send":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_send";
          break;
        case "submited_for_Approver":
          this.confirm_dialog = true;
          this.confirm_type = "submited_for_Approver";
          break;
        case "mark_as_delete":
          this.deleteVisit();
          break;
        case "mark_as_approved":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_approved";
          break;
        case "mark_as_unapproved":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_unapproved";
          break;
        case "download_pdf":
          /* this.download_dialog = true; */
          //this.downloadPDF();
          this.downloadPDFNew();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.orderId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }

      /*   this.actionData.attachments = this.attachments; */
      this.actionData.status = status;
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "order/" + this.orderId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getOrder();
          this.confirm_loading = false;
          this.confirm_dialog = false;
          this.confirm_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
          this.confirm_loading = false;
        });
    },
    ProfesnalInstaService() {
      const _this = this;
      // _this.pageLoading = true;
      if (_this.download_template == "HYC_Pn_Inst") {
        _this.$store
          .dispatch(PUT, {
            url: "professional-service/" + this.orderId,
            data: {
              profe_insta_service: _this.profeinstaservice.profe_insta_service,
              quotation: _this.orderId,
            },
          })
          .then(() => {
            this.downloadPDFNew();
            PaginationEventBus.$emit("histories-download-refresh", true);
          });
      } else {
        const { id } = this.detail;
        const token = JwtService.getToken();
        const url = `${this.$apiURL}quotation/${id}/pdf/download/${this.download_template}?token=${token}&date=${this.download_date}&valid_till=${this.valid_till}`;
        // console.log(url);
        window.open(url, "_blank");
        this.profeinstaservice.profe_insta_service =
          this.detail.profe_insta_service;
        this.download_dialog = false;

        PaginationEventBus.$emit("histories-download-refresh", true);
      }
    },

    downloadPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    routeToJob() {
      this.typeConfirmDialog = true;
    },

    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { duplicate: this.orderId },
        })
      );
    },
    reviseQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { revise: this.orderId },
        })
      );
    },
    requoteQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { requote: this.orderId },
        })
      );
    },

    getOrder() {
      this.$store
        .dispatch(GET, { url: "order-v1/" + this.orderId })
        .then(({ data }) => {
          this.detail = data;
          this.$store.dispatch(SET_VIEW_LINE_ITEM, this.detail?.items);
          this.$store.dispatch(SET_VIEW_CALCULATION, {
            total_selling: this.detail?.total_selling,
            total_cost_value: this.detail?.total_cost_value,
            total_margin: this.detail?.total_margin,
            discount_value: this.detail?.discount_value,
            discount_type: this.lodash.toSafeInteger(
              this.detail?.discount_type
            ),
            main_discount: this.detail?.main_discount,
            lump_discount_value: this.detail?.lump_discount_value,
            lump_discount_type: this.lodash.toSafeInteger(
              this.detail?.lump_discount_type
            ),
            lump_sum_discount: this.detail?.lump_sum_discount,
            discounted_total_selling: this.detail?.discounted_total_selling,
            grand_total: this.detail?.grand_total,
          });
          this.profeinstaservice.profe_insta_service =
            this.detail.profe_insta_service;
          this.db_approval = data.db_approval;
          this.moreActions = data.more_actions;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Order", route: "orders" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
    get_remark_title() {
      if (this.detail.status == 2) {
        return "Confirmed Remark";
      } else if (this.detail.status == 3) {
        return "Rejected Remark";
      } else {
        return null;
      }
    },
  },
  created() {
    // console.log(this.$route);

    this.orderId = this.lodash.toSafeInteger(this.$route.params.id);
    /*  this.orderTab = this.$route.query.tab; */

    // console.log(this.orderTab, "this.orderTab");

    this.templateList = [
      /* { title: "HYC Pn Rm", value: "HYC_Pn_Rm" },
      { title: "HYC Rm", value: "HYC_Rm" },
      { title: "HYC Rm Inst", value: "HYC_Rm_Inst" }, */
      { title: "HYC Pn Inst", value: "HYC_Pn_Inst" },
      { title: "HYC Pn None", value: "HYC_Pn_None" },
    ];
  },
  mounted() {
    this.getOrder();
    this.getQuotationSetting();
    this.userDetails = JwtService.currentUser();
    //console.log(this.userDetails, "hello");
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
    formattedDownloadDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.download_date).format(Config.dateFormat);
      }
      return this.download_date;
    },
    formattedValidDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.valid_till).format(Config.dateFormat);
      }
      return this.valid_till;
    },
    orderTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.orderTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
  },
  components: {
    CustomStatus,
    DetailTemplate,
    VisitInternalAll,
    DeleteDialog,
    SendMail,
    Dialog,
    AdditionalDocuments,
    RadioInputCustom,
    "file-upload": FileUpload,
    InternalInvoice,
    TextAreaField,
    /*   Barcode, */
    ViewLineItem,
    InternalHistoryDetail,
    PDFHistoryDetail,
    editor: TinyMCE,
    FullDialog,
    ChatNote,
  },
};
</script>
